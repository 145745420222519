div.contentsBox p.preview {
  width: 100px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #44b035;
  border-radius: 4px;
  line-height: 24px;
  position: absolute;
  top: 10px;
  right: 200px;
}

div.contentsBox p.preview:hover {
  color: #fff;
  background: #44b035;
}

div#header h1.rekihaku-logo {
  width: 60px;
  height: 60px;
  background: url("rekihaku-logo.74320e21.png") 0 0 / contain no-repeat;
}

table.contentsList a {
  white-space: nowrap;
}

.login_rehikaku_logo {
  width: 100px;
  height: 100px;
  background: url("rekihaku-logo.74320e21.png") 0 0 / contain no-repeat;
  margin: 100px auto 30px;
  display: block;
}

.NotificationOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.NotificationOverlayItem {
  min-height: 50px;
  background-color: #fff;
  border-bottom: 1px solid gray;
  flex-direction: row;
  display: flex;
}

.NotificationOverlayItem__messages {
  flex-grow: 1;
  margin: 5px 20px;
}

.NotificationOverlayItem__messages ul {
  margin-left: 25px;
}

.NotificationOverlayItem__close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url("lang_delete.a703b3b7.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 24px;
  align-self: center;
  margin-right: 50px;
  display: block;
}

.NotificationOverlayItem__close:hover {
  background-position: top;
}

@media print {
  .NotificationOverlay {
    display: none;
  }
}

.PrintQR {
  page-break-after: always;
  font-size: 10pt;
}

.PrintQR__image {
  width: 128px;
  margin: .5em auto;
}

.PrintQR__image__img {
  width: 100%;
}

.PrintQR__facilityName {
  text-align: center;
  margin: .5em;
  font-size: 12pt;
  font-weight: 700;
}

.PrintQR__qr {
  text-align: center;
  margin: 2em 0;
}

.PrintQR__description {
  white-space: pre-wrap;
  margin-bottom: 1em;
}

.ProgressOverlay {
  background-color: #fff6;
  position: fixed;
  inset: 0;
}

audio {
  width: 240px;
  object-fit: contain;
  margin: 10px 0;
  display: block;
}

div.preTextWrapper {
  white-space: pre-wrap;
}

input[type="number"] {
  background: #efefef;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 5px;
}

input[type="number"].full {
  width: calc(100% - 12px);
}

input[type="number"].half {
  width: calc(50% - 12px);
  margin-bottom: 10px;
}

input[type="number"].number {
  height: 72px;
  text-align: center;
  font-size: 48px;
}

label.file {
  color: #44b035;
  border: 2px solid #44b035;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 14px;
}

label.file:hover {
  color: #fff;
  background: #44b035;
}

label.file input {
  display: none;
}

video {
  width: 240px;
  height: 160px;
  object-fit: contain;
  margin: 10px 0;
  display: block;
}

img[data-noimage] {
  background: #333 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAAA0CAYAAABVR6BhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAO9SURBVHhe7ZyBceIwEEV9N9cEtBHKIJQRyoCUEcoAyghtkDLu7vnYGZ1Gtta2bOPMfzMaHFuWdldfK9kw+fHy8vK7EiLDz8enEK1IKMKFhCJcSCjChYQiXEgowoWEIlxIKMKFhCJcSCjChYQiXEgowoWEIlxIKMJF9mcGq9Wq/vz6+qo/vXBf13uGYrY24bHn7e2tbuf9/f1xRkBWKASOcrlc3MH722b18fFRvb6+TioWs7WJ+/1e23O73arT6fQ4+z+Hw6Far9fVfr9/nBHgXnoYdASwBLA1VRAHYtlut9X5fH56fz4/P2tbnwG3UMgozLYlQNZIlev1WmdFsgV/k/Vyy5X4h1sozEZScltqXwqI5Hg81sdkGpHHLRSCi1gQyneYhfjDXmUpy+ncdHo8tjW+VFZhkFiDKXMMGELxiJ46ZmtXO+fysXS/vx6fbljjWdtZ7wl0H8KnE4QHLGsc027TE0lpciLhOksUgY7tJA5t/vfxkX7iQWVp5L6YphiNFdtOGQUIzpCNLSJD5QzAZrOpdrtdXTjGCa5RZwoIYNNgM2D4SYAZLLOT49xGeIiPtGnFCM/F10LGjG1noQCdEmRTrhfqMwA8daDuGM5xjTpd2+6Kzd6UUBgIJgIBJ3MgDINjbDQBxQzxEVvozwrY+6u4xIwd215C6bOxpR71uS8MfEzY9liYECwdxzAJOJ+6ZjCoBD1kLh+n6LeXUIAOCbS3UwsqMySH1SFV9oHApQrtIRDaJ2jMsCZydnI/bYZM6WPIFP32FgqQAkm/8cxKQR2E1aZ4gzrUTW3iPBCMVEHUtMmSgkiabPHYmbJvSh9Dpuh3kFC6bGwxzOOIQd14xnpBvKnCxq5pDQ/pYmfIlD6GTNHvIKEASxCGllpvS0AgUkX0Z7BQGABSOUIpMTvEczJYKEAqZ91ryypc7yIk6i4tC8zl4xT9FhEK5Da27GdYojwOUYe6BGBJzOXjFP0WE0puY2sbSMSUgzo4ktt0Phtz+ThFv8WEArmNrb3saVM+GYk6SxOJUdJHBrQpQ8eMHduiQrGNbZNzOGPvNHjhEzrFMU7wXQTXqbtESvpIliYDxPFMiWHs2Lp/M8sXS14wCOdwMrVpwhHaDNdKO8aJvtmkj60xLJ3Y0vbWFvDBvnxLUcJHBjiceNxLGwioyb6xYjvrP/sjEDgBOLK0pxwPJXzs00bp2Oq/QgoXRfco4vsioQgXEopwIaEIFxKKcCGhCBcSinAhoQgXEopwIaEIFxKKcCGhCBcSinBQVX8AJoQYU8Y4eCEAAAAASUVORK5CYII=") center no-repeat;
}

div.preImageWrapper {
  width: 350px;
  height: 250px;
  marin: 20px;
  float: left;
  clear: both;
  text-align: center;
  background: #333 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAAA0CAYAAABVR6BhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAO9SURBVHhe7ZyBceIwEEV9N9cEtBHKIJQRyoCUEcoAyghtkDLu7vnYGZ1Gtta2bOPMfzMaHFuWdldfK9kw+fHy8vK7EiLDz8enEK1IKMKFhCJcSCjChYQiXEgowoWEIlxIKMKFhCJcSCjChYQiXEgowoWEIlxIKMJF9mcGq9Wq/vz6+qo/vXBf13uGYrY24bHn7e2tbuf9/f1xRkBWKASOcrlc3MH722b18fFRvb6+TioWs7WJ+/1e23O73arT6fQ4+z+Hw6Far9fVfr9/nBHgXnoYdASwBLA1VRAHYtlut9X5fH56fz4/P2tbnwG3UMgozLYlQNZIlev1WmdFsgV/k/Vyy5X4h1sozEZScltqXwqI5Hg81sdkGpHHLRSCi1gQyneYhfjDXmUpy+ncdHo8tjW+VFZhkFiDKXMMGELxiJ46ZmtXO+fysXS/vx6fbljjWdtZ7wl0H8KnE4QHLGsc027TE0lpciLhOksUgY7tJA5t/vfxkX7iQWVp5L6YphiNFdtOGQUIzpCNLSJD5QzAZrOpdrtdXTjGCa5RZwoIYNNgM2D4SYAZLLOT49xGeIiPtGnFCM/F10LGjG1noQCdEmRTrhfqMwA8daDuGM5xjTpd2+6Kzd6UUBgIJgIBJ3MgDINjbDQBxQzxEVvozwrY+6u4xIwd215C6bOxpR71uS8MfEzY9liYECwdxzAJOJ+6ZjCoBD1kLh+n6LeXUIAOCbS3UwsqMySH1SFV9oHApQrtIRDaJ2jMsCZydnI/bYZM6WPIFP32FgqQAkm/8cxKQR2E1aZ4gzrUTW3iPBCMVEHUtMmSgkiabPHYmbJvSh9Dpuh3kFC6bGwxzOOIQd14xnpBvKnCxq5pDQ/pYmfIlD6GTNHvIKEASxCGllpvS0AgUkX0Z7BQGABSOUIpMTvEczJYKEAqZ91ryypc7yIk6i4tC8zl4xT9FhEK5Da27GdYojwOUYe6BGBJzOXjFP0WE0puY2sbSMSUgzo4ktt0Phtz+ThFv8WEArmNrb3saVM+GYk6SxOJUdJHBrQpQ8eMHduiQrGNbZNzOGPvNHjhEzrFMU7wXQTXqbtESvpIliYDxPFMiWHs2Lp/M8sXS14wCOdwMrVpwhHaDNdKO8aJvtmkj60xLJ3Y0vbWFvDBvnxLUcJHBjiceNxLGwioyb6xYjvrP/sjEDgBOLK0pxwPJXzs00bp2Oq/QgoXRfco4vsioQgXEopwIaEIFxKKcCGhCBcSinAhoQgXEopwIaEIFxKKcCGhCBcSinBQVX8AJoQYU8Y4eCEAAAAASUVORK5CYII=") center no-repeat;
  margin-bottom: 50px;
}

a.errorboundary__logout {
  color: #44b035;
  cursor: pointer;
  font-weight: bold;
}

p.qrcode_attention {
  background: #ddd;
  margin: 1em 0;
  padding: 1em .5em;
}

label.guide_language {
  width: 100px;
  display: inline-block;
}

input.marginBottom {
  margin-bottom: 10px;
}

/*# sourceMappingURL=index.e04c2b28.css.map */
