/* default.css への追加。 */
div.contentsBox p.preview {
  position: absolute;
  top: 10px;
  right: 200px;
  border: 2px solid #44b035;
  width: 100px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

div.contentsBox p.preview:hover {
  background: #44b035;
  color: #fff;
}

div#header h1.rekihaku-logo {
  background: url("images/rekihaku-logo.png") no-repeat;
  background-size: contain;
  width: 60px;
  height: 60px;
}

table.contentsList a {
  white-space: nowrap;
}

.login_rehikaku_logo {
  margin: 100px auto 30px;
  display: block;
  background: url("images/rekihaku-logo.png") no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
}

/* コンポーネント別スタイル。 */

.NotificationOverlay {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
}

.NotificationOverlayItem {
  background-color: white;
  border-bottom: 1px solid gray;
  min-height: 50px;
  display: flex;
  flex-direction: row;
}

.NotificationOverlayItem__messages {
  flex-grow: 1;
  margin: 5px 20px;
}

.NotificationOverlayItem__messages ul {
  margin-left: 25px;
}

.NotificationOverlayItem__close {
  width: 24px;
  height: 24px;
  background-image: url("images/lang_delete.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 24px;
  display: block;
  cursor: pointer;
  align-self: center;
  margin-right: 50px;
}

.NotificationOverlayItem__close:hover {
  background-position: center top;
}

@media print {
  .NotificationOverlay {
    display: none;
  }
}

.PrintQR {
  page-break-after: always;
  font-size: 10pt;
}

.PrintQR__image {
  margin: 0.5em auto;
  width: 128px;
}

.PrintQR__image__img {
  width: 100%;
}

.PrintQR__facilityName {
  text-align: center;
  font-size: 12pt;
  font-weight: 700;
  margin: 0.5em;
}

.PrintQR__qr {
  text-align: center;
  margin: 2em 0em;
}

.PrintQR__description {
  white-space: pre-wrap;
  margin-bottom: 1em;
}

.ProgressOverlay {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.4);
}

/* 要素別スタイル。 */

audio {
  display: block;
  margin: 10px 0;
  width: 240px;
  object-fit: contain;
}

div.preTextWrapper {
  white-space: pre-wrap;
}

input[type="number"] {
  padding: 0 5px;
  background: #efefef;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="number"].full {
  width: calc(100% - 12px);
}

input[type="number"].half {
  width: calc(50% - 12px);
  margin-bottom: 10px;
}

input[type="number"].number {
  font-size: 48px;
  height: 72px;
  text-align: center;
}

label.file {
  border: 2px solid #44b035;
  padding: 2px 5px;
  border-radius: 4px;
  color: #44b035;
  font-size: 14px;
}

label.file:hover {
  background: #44b035;
  color: #fff;
}

label.file input {
  display: none;
}

video {
  display: block;
  margin: 10px 0;
  width: 240px;
  height: 160px;
  object-fit: contain;
}

img[data-noimage] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAAA0CAYAAABVR6BhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAO9SURBVHhe7ZyBceIwEEV9N9cEtBHKIJQRyoCUEcoAyghtkDLu7vnYGZ1Gtta2bOPMfzMaHFuWdldfK9kw+fHy8vK7EiLDz8enEK1IKMKFhCJcSCjChYQiXEgowoWEIlxIKMKFhCJcSCjChYQiXEgowoWEIlxIKMJF9mcGq9Wq/vz6+qo/vXBf13uGYrY24bHn7e2tbuf9/f1xRkBWKASOcrlc3MH722b18fFRvb6+TioWs7WJ+/1e23O73arT6fQ4+z+Hw6Far9fVfr9/nBHgXnoYdASwBLA1VRAHYtlut9X5fH56fz4/P2tbnwG3UMgozLYlQNZIlev1WmdFsgV/k/Vyy5X4h1sozEZScltqXwqI5Hg81sdkGpHHLRSCi1gQyneYhfjDXmUpy+ncdHo8tjW+VFZhkFiDKXMMGELxiJ46ZmtXO+fysXS/vx6fbljjWdtZ7wl0H8KnE4QHLGsc027TE0lpciLhOksUgY7tJA5t/vfxkX7iQWVp5L6YphiNFdtOGQUIzpCNLSJD5QzAZrOpdrtdXTjGCa5RZwoIYNNgM2D4SYAZLLOT49xGeIiPtGnFCM/F10LGjG1noQCdEmRTrhfqMwA8daDuGM5xjTpd2+6Kzd6UUBgIJgIBJ3MgDINjbDQBxQzxEVvozwrY+6u4xIwd215C6bOxpR71uS8MfEzY9liYECwdxzAJOJ+6ZjCoBD1kLh+n6LeXUIAOCbS3UwsqMySH1SFV9oHApQrtIRDaJ2jMsCZydnI/bYZM6WPIFP32FgqQAkm/8cxKQR2E1aZ4gzrUTW3iPBCMVEHUtMmSgkiabPHYmbJvSh9Dpuh3kFC6bGwxzOOIQd14xnpBvKnCxq5pDQ/pYmfIlD6GTNHvIKEASxCGllpvS0AgUkX0Z7BQGABSOUIpMTvEczJYKEAqZ91ryypc7yIk6i4tC8zl4xT9FhEK5Da27GdYojwOUYe6BGBJzOXjFP0WE0puY2sbSMSUgzo4ktt0Phtz+ThFv8WEArmNrb3saVM+GYk6SxOJUdJHBrQpQ8eMHduiQrGNbZNzOGPvNHjhEzrFMU7wXQTXqbtESvpIliYDxPFMiWHs2Lp/M8sXS14wCOdwMrVpwhHaDNdKO8aJvtmkj60xLJ3Y0vbWFvDBvnxLUcJHBjiceNxLGwioyb6xYjvrP/sjEDgBOLK0pxwPJXzs00bp2Oq/QgoXRfco4vsioQgXEopwIaEIFxKKcCGhCBcSinAhoQgXEopwIaEIFxKKcCGhCBcSinBQVX8AJoQYU8Y4eCEAAAAASUVORK5CYII=)
    no-repeat #333333 center;
}

div.preImageWrapper {
  width: 350px;
  height: 250px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAAA0CAYAAABVR6BhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAO9SURBVHhe7ZyBceIwEEV9N9cEtBHKIJQRyoCUEcoAyghtkDLu7vnYGZ1Gtta2bOPMfzMaHFuWdldfK9kw+fHy8vK7EiLDz8enEK1IKMKFhCJcSCjChYQiXEgowoWEIlxIKMKFhCJcSCjChYQiXEgowoWEIlxIKMJF9mcGq9Wq/vz6+qo/vXBf13uGYrY24bHn7e2tbuf9/f1xRkBWKASOcrlc3MH722b18fFRvb6+TioWs7WJ+/1e23O73arT6fQ4+z+Hw6Far9fVfr9/nBHgXnoYdASwBLA1VRAHYtlut9X5fH56fz4/P2tbnwG3UMgozLYlQNZIlev1WmdFsgV/k/Vyy5X4h1sozEZScltqXwqI5Hg81sdkGpHHLRSCi1gQyneYhfjDXmUpy+ncdHo8tjW+VFZhkFiDKXMMGELxiJ46ZmtXO+fysXS/vx6fbljjWdtZ7wl0H8KnE4QHLGsc027TE0lpciLhOksUgY7tJA5t/vfxkX7iQWVp5L6YphiNFdtOGQUIzpCNLSJD5QzAZrOpdrtdXTjGCa5RZwoIYNNgM2D4SYAZLLOT49xGeIiPtGnFCM/F10LGjG1noQCdEmRTrhfqMwA8daDuGM5xjTpd2+6Kzd6UUBgIJgIBJ3MgDINjbDQBxQzxEVvozwrY+6u4xIwd215C6bOxpR71uS8MfEzY9liYECwdxzAJOJ+6ZjCoBD1kLh+n6LeXUIAOCbS3UwsqMySH1SFV9oHApQrtIRDaJ2jMsCZydnI/bYZM6WPIFP32FgqQAkm/8cxKQR2E1aZ4gzrUTW3iPBCMVEHUtMmSgkiabPHYmbJvSh9Dpuh3kFC6bGwxzOOIQd14xnpBvKnCxq5pDQ/pYmfIlD6GTNHvIKEASxCGllpvS0AgUkX0Z7BQGABSOUIpMTvEczJYKEAqZ91ryypc7yIk6i4tC8zl4xT9FhEK5Da27GdYojwOUYe6BGBJzOXjFP0WE0puY2sbSMSUgzo4ktt0Phtz+ThFv8WEArmNrb3saVM+GYk6SxOJUdJHBrQpQ8eMHduiQrGNbZNzOGPvNHjhEzrFMU7wXQTXqbtESvpIliYDxPFMiWHs2Lp/M8sXS14wCOdwMrVpwhHaDNdKO8aJvtmkj60xLJ3Y0vbWFvDBvnxLUcJHBjiceNxLGwioyb6xYjvrP/sjEDgBOLK0pxwPJXzs00bp2Oq/QgoXRfco4vsioQgXEopwIaEIFxKKcCGhCBcSinAhoQgXEopwIaEIFxKKcCGhCBcSinBQVX8AJoQYU8Y4eCEAAAAASUVORK5CYII=)
    no-repeat #333333 center;
  marin: 20px;
  float: left;
  clear: both;
  margin-bottom: 50px;
  text-align: center;
}

a.errorboundary__logout {
  color: #44b035;
  font-weight: bold;
  cursor: pointer;
}

p.qrcode_attention {
  background: #ddd;
  margin: 1em 0;
  padding: 1em 0.5em;
}

label.guide_language {
  display: inline-block;
  width: 100px;
}

input.marginBottom {
  margin-bottom: 10px;
}
